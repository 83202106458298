import { Button } from '@montugroup/design-system';
import { Box, Link, styled, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useNavigate } from 'react-router-dom';

import constants from '@/pcp/constants/constants';
import type { PCPErrorType } from '@/pcp/constants/types';

type ErrorProps = {
  type: PCPErrorType;
};

type ErrorContent = {
  title: string;
  body: React.ReactNode[];
  imgSrc: string;
};

const contentSchema: { [key in ErrorProps['type']]: ErrorContent } = {
  404: {
    title: 'Page not found',
    body: [
      "The page you're looking for could not be found. Try going back to the previous page, or visit the Alternaleaf homepage to explore other options."
    ],
    imgSrc: '/assets/error404.svg'
  },
  500: {
    title: "Oops! Looks like we're temporarily offline",
    body: [
      "We're working hard to resolve this issue and will be back online soon. Return to the homepage or check out our FAQs to learn more about holistic health."
    ],
    imgSrc: '/assets/error500.svg'
  },
  no_token: {
    title: 'Something appears to be missing',
    body: [
      "Whoops! It looks like you're missing a valid access token.",
      "If you have an active appointment booking, you can refresh your token by coming via the link in the emails we've sent you.",
      <>
        Can&apos;t find the email? Get in touch with our Patient Experience Team by calling{' '}
        <Link href="tel:1800864878">1800 864 878</Link>, or emailing{' '}
        <Link href="mailto:info@alternaleaf.com.au">info@alternaleaf.com.au</Link>. They&apos;ll be happy to assist.
      </>
    ],
    imgSrc: '/assets/errorGeneric.svg'
  },
  bad_token: {
    title: 'Whoops! It looks like your link is invalid or has expired',
    body: [
      "If you have an active appointment booking, you can refresh your token by coming via the link in the emails we've sent you.",
      'Just cancelled your consult? Book a new appointment to receive a new link to your portal.',
      <>
        If you require further assistance, you can{' '}
        <Link href="https://www.alternaleaf.com.au/contact-us" target="_blank">
          contact us
        </Link>{' '}
        to get in touch with our support team.
      </>
    ],
    imgSrc: '/assets/errorGeneric.svg'
  }
};

const Wrapper = styled(Box)(({ theme: t }) => ({
  marginTop: '1rem',
  width: '100%',
  flexGrow: 1,
  display: 'flex',
  flexDirection: 'column',
  textAlign: 'center',

  [t.breakpoints.up('md')]: {
    marginTop: '0',
    flexDirection: 'row',
    alignItems: 'flex-start',
    justifyContent: 'space-around',
    textAlign: 'left'
  }
}));

const Image = styled('img')(({ theme: t }) => ({
  maxWidth: '26.5rem',

  [t.breakpoints.down('md')]: {
    maxWidth: '21.5rem',
    margin: '0 auto'
  }
}));

const Content = styled(Box)(({ theme: t }) => ({
  marginTop: '2.5rem',
  display: 'flex',
  flexDirection: 'column',

  [t.breakpoints.up('md')]: {
    marginTop: '0',
    maxWidth: '23rem',
    flexGrow: 1
  }
}));

const StyledButton = styled(Button)({
  maxWidth: '30rem',
  display: 'block',
  margin: 'auto',
  textAlign: 'center'
});

export default function Error({ type }: ErrorProps) {
  const content = contentSchema[type];
  const navigate = useNavigate();

  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('md'));
  const isShort = useMediaQuery('(max-height:1000px)');
  const isMobile = isNarrow && isShort;

  const handleBackButtonClick = () => {
    navigate(-1);
  };

  return (
    <Wrapper>
      <Image src={content.imgSrc} alt={type} />

      <Content sx={{ flexGrow: isMobile ? 1 : undefined }}>
        <Typography variant="h5" component="h1" color="primary" mb="0.5rem">
          {content.title}
        </Typography>

        {content.body.map((text, index) => (
          <Typography key={index} variant="body1" mt="1rem">
            {text}
          </Typography>
        ))}

        <Box height="2rem" />

        {isMobile && <Box flexGrow={1} />}

        {(type === '500' || type === '404') && (
          <StyledButton href={constants.alternaleafUrl} variant="contained" fullWidth>
            Alternaleaf home
          </StyledButton>
        )}

        {type === '500' ? (
          <StyledButton href={constants.faqUrl} variant="outlined" sx={{ marginTop: '0.5rem' }} fullWidth>
            FAQs
          </StyledButton>
        ) : (
          <StyledButton onClick={handleBackButtonClick} variant="outlined" sx={{ marginTop: '0.5rem' }} fullWidth>
            Back
          </StyledButton>
        )}
      </Content>
    </Wrapper>
  );
}
