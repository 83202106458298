import type { Step } from '@/components/form-builder/FormBuilder';
import { isPreScreeningPayload } from '@/constants/test.config';
import type { FormPayload } from '@/constants/types';
import { useCallback } from 'react';
import type { FieldValues, UseFormReturn } from 'react-hook-form';

export type TestConfig = {
  mockPayload: FormPayload;
  formId: string;
  prevStepId: string;
  mapping?: Record<string, any>;
};

export enum FormSubmissionStep {
  PRE_SCREENING = 3,
  DEFAULT = 2
}

export const useFormTestShortcut = (
  methods: UseFormReturn<FieldValues>,
  steps: Step[],
  setCurrentStepIndex: (index: number) => void,
  isEnabled: boolean,
  testConfig?: TestConfig
) => {
  const handleTestShortcut = useCallback(
    (email?: string) => {
      if (!testConfig?.mockPayload || !isEnabled) {
        return;
      }

      const { mockPayload, formId, prevStepId } = testConfig;

      let payload = {
        ...testConfig.mockPayload
      };

      let lastStepIndex = steps.length - FormSubmissionStep.DEFAULT;
      if (formId === 'pre-screening' && isPreScreeningPayload(mockPayload)) {
        lastStepIndex = steps.length - FormSubmissionStep.PRE_SCREENING;
        payload = {
          ...mockPayload,
          ...(email && {
            email_and_privacy: {
              ...mockPayload.email_and_privacy,
              email
            }
          })
        };
      } else if (formId === 'pre-consultation' || formId === 'quality-of-life') {
        payload = {
          ...mockPayload,
          ...(email && { email })
        };
      }

      methods.reset(payload);
      setCurrentStepIndex(lastStepIndex);

      const newPrevStepArray = new Set([...Array(lastStepIndex).keys()]);
      sessionStorage.setItem(prevStepId, JSON.stringify(Array.from(newPrevStepArray)));
    },
    [methods, setCurrentStepIndex, steps.length, testConfig, isEnabled]
  );

  const handleFullReset = useCallback(() => {
    if (!testConfig?.formId || !isEnabled) {
      return;
    }

    const { formId, prevStepId } = testConfig;

    setCurrentStepIndex(0);
    methods.reset({});

    setTimeout(() => {
      sessionStorage.removeItem(prevStepId);
      sessionStorage.removeItem(formId);
    }, 100);

    setTimeout(() => {
      methods.reset({});
    }, 500);
  }, [methods, setCurrentStepIndex, testConfig, isEnabled]);

  return { handleTestShortcut, handleFullReset };
};
