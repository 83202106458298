import { Box, Divider, styled } from '@mui/material';
import { useContext, useEffect } from 'react';

import useFeatureFlags from '@/hooks/useFeatureFlags';
import StepContextWrapper from '@/pcp/components/components/StepContextWrapper';
import type { PCPStepConfig } from '@/pcp/constants/types';
import { PCPDataContext } from '@/pcp/pages/PCPortal';
import { getSteps } from '@/pcp/utils/helpers';

type StepsProps = {
  stepsConfig: PCPStepConfig[];
};

export const Wrapper = styled(Box)(({ theme: t }) => ({
  marginBottom: '2.5rem',
  [t.breakpoints.down('md')]: {
    // forcing past container gutters on mobile
    position: 'relative',
    left: 'calc(-1 * var(--container-gutter))',
    width: 'calc(100% + (2 * var(--container-gutter)))'
  }
}));

export default function Steps({ stepsConfig }: StepsProps) {
  const { userData, setStepsConfig } = useContext(PCPDataContext);
  const steps = getSteps(userData, stepsConfig);
  const { flags } = useFeatureFlags();

  // FF Check - Remove Step
  for (let i = steps.length - 1; i >= 0; i--) {
    const step = steps[i];
    const isEnabled = step.featureFlag ? flags[step.featureFlag] : true;
    if (!isEnabled) {
      steps.splice(i, 1);
    }
  }

  const firstStepComplete = steps?.[0]?.completed;

  useEffect(() => {
    setStepsConfig(stepsConfig);
  }, [stepsConfig]);

  return (
    <Wrapper>
      {!firstStepComplete && <Divider />}
      {steps.map((stepData) => (
        <StepContextWrapper key={stepData.stepId} allSteps={steps} stepData={stepData}>
          {stepData.stepComponent}
        </StepContextWrapper>
      ))}
    </Wrapper>
  );
}
