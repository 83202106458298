import { Button } from '@montugroup/design-system';
import { Box, Divider, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useContext } from 'react';

import { PCPStepContext } from '@/pcp/components/components/StepContextWrapper';

type StepProps = {
  buttonVariant?: 'contained' | 'outlined';
  onButtonClick?: (mouseEvent: React.MouseEvent<HTMLButtonElement>) => void;
  divider?: boolean;
};

const stepPadding = '1rem';

export default function Step({ buttonVariant = 'contained', onButtonClick, divider }: StepProps) {
  const { stepId, url, title, subtitle, buttonText, buttonId, isLink, openLinkNewTab, completed, submitted } =
    useContext(PCPStepContext);

  const theme = useTheme();
  const isNarrow = useMediaQuery(theme.breakpoints.down('sm'));

  const showCompleted = completed || submitted;

  return (
    <Box
      minHeight={84}
      display="flex"
      flexDirection="column"
      bgcolor={showCompleted ? 'action.selected' : 'none'}
      data-testid={stepId}
    >
      <Box flexGrow={1} display="flex" flexDirection="row" alignItems="center" justifyContent="space-between">
        <Box margin={stepPadding}>
          <Typography variant="body1" color={showCompleted ? 'text.secondary' : 'text.primary'}>
            {title}
          </Typography>
          <Typography variant="body2" color="text.secondary" mt={0.5}>
            {subtitle}
          </Typography>
        </Box>
        <Button
          id={buttonId}
          href={isLink ? url : undefined}
          {...(isLink && openLinkNewTab ? { target: '_blank' } : {})}
          onClick={onButtonClick}
          variant={buttonVariant}
          size={isNarrow ? 'medium' : 'large'}
          disabled={showCompleted}
          style={{
            minWidth: isNarrow ? '9.5rem' : '12rem',
            margin: stepPadding
          }}
        >
          {buttonText}
        </Button>
      </Box>
      {divider && <Divider />}
    </Box>
  );
}
