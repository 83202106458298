import type { ErrorInfo } from 'react';
import { createRoot } from 'react-dom/client';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from 'react-router-dom';

import Logger from '@/utils/logger';
import withFeatureFlagProvider from '@/utils/withFeatureFlagProvider';

import App from './App';
import ErrorFallback from './components/ErrorFallback';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

const AppWithFF = withFeatureFlagProvider(App);

const logger = new Logger('FORMS_APP');
const container = document.getElementById('root');
const root = createRoot(container!);

const logError = (error: Error, info: ErrorInfo) => {
  logger.error(error, info);
};

root.render(
  <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
    <BrowserRouter>
      <AppWithFF />
    </BrowserRouter>
  </ErrorBoundary>
);
