import { ChevronLeft, ChevronRight, CloseRounded } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField, Typography, styled } from '@mui/material';
import { useEffect, useRef, useState } from 'react';

interface TestingMenuProps {
  onReset: () => void;
  skipToEnd: (email?: string) => void;
}

const TestingMenuContainer = styled(Box)(({ theme }) => ({
  position: 'fixed',
  top: theme.spacing(4),
  right: theme.spacing(4),
  zIndex: 1000,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.paper,
  padding: theme.spacing(2),
  boxShadow: theme.shadows[2]
}));

const QuickSubmitContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  marginTop: theme.spacing(2),
  border: '1px solid',
  borderColor: theme.palette.divider,
  borderRadius: theme.shape.borderRadius
}));

const QuickSubmitForm = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginTop: theme.spacing(2)
}));

export function TestingMenu({ onReset, skipToEnd }: TestingMenuProps) {
  const [email, setEmail] = useState('');
  const [showQuickSubmit, setShowQuickSubmit] = useState(false);
  const emailInputRef = useRef<HTMLInputElement>(null);
  const handleReset = () => {
    onReset();
    setEmail('');
    setShowQuickSubmit(false);
  };

  const handleSkipToEnd = () => {
    skipToEnd(email);
    setEmail('');
    setShowQuickSubmit(false);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    handleSkipToEnd();
  };

  useEffect(() => {
    if (showQuickSubmit) {
      emailInputRef.current?.focus();
    }
  }, [showQuickSubmit]);

  const handleSkipToFinalStepClick = () => {
    setShowQuickSubmit(true);
  };

  return (
    <TestingMenuContainer>
      <Stack direction="row" spacing={2} justifyContent="space-between">
        <Button variant="contained" color="primary" onClick={handleReset}>
          <ChevronLeft />
          Reset Form
        </Button>
        <Button variant="contained" color="primary" onClick={handleSkipToFinalStepClick}>
          Skip to Final Step
          <ChevronRight />
        </Button>
      </Stack>
      {showQuickSubmit && (
        <QuickSubmitContainer>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="body2" paddingY={1}>
              Quick submit with email
            </Typography>
            <IconButton onClick={() => setShowQuickSubmit(false)}>
              <CloseRounded fontSize="small" />
            </IconButton>
          </Stack>
          <QuickSubmitForm onSubmit={handleSubmit}>
            <TextField
              inputRef={emailInputRef}
              variant="outlined"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              size="small"
              fullWidth
            />
            <Button variant="contained" color="primary" type="submit" disabled={!email} fullWidth>
              Go to Final Step
            </Button>
          </QuickSubmitForm>
        </QuickSubmitContainer>
      )}
    </TestingMenuContainer>
  );
}
