export const appEnvStrings = ['development', 'developmentFullStack', 'staging', 'production'] as const;
export type AppEnv = (typeof appEnvStrings)[number];

// forms which have their own environments (deployed seperately)
export const formBuilderEnvFormIdStrings = ['screening', 'consult'] as const;
export type FormBuilderEnvFormId = (typeof formBuilderEnvFormIdStrings)[number];

// all of the above plus forms which do not need their own environment
export const formBuilderFormIdStrings = [...formBuilderEnvFormIdStrings, 'qol'] as const;
export type FormBuilderFormId = (typeof formBuilderFormIdStrings)[number];

// all build environment options
export const formEnvStrings = [...formBuilderEnvFormIdStrings, 'portal', 'all'] as const;
export type FormConfig = (typeof formEnvStrings)[number];

export const getAppEnv = (): AppEnv => {
  const mode = (import.meta.env.VITE_MODE || import.meta.env.MODE) as AppEnv | undefined;
  if (!mode || !appEnvStrings.includes(mode)) {
    return 'production';
  }
  return mode;
};

export const viteFFOverrides = (): object | null => {
  const ffOverrides = import.meta.env?.VITE_FF_OVERRIDES;

  if (ffOverrides) {
    try {
      return JSON.parse(ffOverrides);
    } catch (e) {
      throw new Error(`VITE_FF_OVERRIDES is not a valid JSON string ${e}`);
    }
  }

  return null;
};

export const getFormConfig = (): FormConfig => {
  const formConfig = import.meta.env?.VITE_FORM_CONFIG;
  if (!formConfig || !formEnvStrings.includes(formConfig)) {
    return 'all';
  }
  return formConfig;
};

export default null;
