import { ArrowDropUp as ArrowDropUpIcon, List as ListIcon } from '@mui/icons-material';
import { Box, Button, Checkbox, MenuItem, MenuList, Popover, useMediaQuery, useTheme } from '@mui/material';
import { ArrowDropDownIcon } from '@mui/x-date-pickers';
import { useContext, useRef, useState } from 'react';

import useFeatureFlags from '@/hooks/useFeatureFlags';
import type { PCPStepConfig } from '@/pcp/constants/types';
import { PCPDataContext } from '@/pcp/pages/PCPortal';
import { getValueByPath } from '@/pcp/utils/helpers';

type TestStepsSelectProps = {
  disabled: boolean;
};

export default function TestStepsSelect({ disabled }: TestStepsSelectProps) {
  const anchorRef = useRef<HTMLButtonElement>(null);
  const [open, setOpen] = useState(false);
  const { userData, updateUserData, stepsConfig } = useContext(PCPDataContext);
  const { flags } = useFeatureFlags();

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down('lg'));

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleRequiredCheckboxClick = (config: PCPStepConfig) => {
    updateUserData([[config?.requiredApiPath as string]]);
  };

  const handleCompletedCheckboxClick = (config: PCPStepConfig) => {
    updateUserData([[config.completedApiPath]]);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // FF Check - Remove Step
  for (let i = stepsConfig.length - 1; i >= 0; i--) {
    const step = stepsConfig[i];
    const isEnabled = step.featureFlag ? flags[step.featureFlag] : true;
    if (!isEnabled) {
      stepsConfig.splice(i, 1);
    }
  }

  return (
    <>
      <Button
        ref={anchorRef}
        disabled={disabled}
        id="steps-select-button"
        aria-controls={open ? 'steps-select' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="contained"
        onClick={handleToggle}
        sx={{ height: '2.6rem', borderRadius: '4px', width: isMobile ? '4rem' : '7rem' }}
      >
        {isMobile ? (
          <ListIcon />
        ) : (
          <>
            {'STEPS'}
            <Box sx={{ height: '1.6rem', width: '1rem', ml: '0.1rem', color: '#fff' }}>
              {open ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Box>
          </>
        )}
      </Button>
      <Popover
        open={open}
        id="steps-select"
        aria-labelledby="steps-select-button"
        anchorEl={anchorRef.current}
        onClose={handleClose}
        disableScrollLock
        sx={{ top: 1 }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center'
        }}
      >
        <Box
          display="flex"
          flexDirection="row"
          height="1.7rem"
          position="relative"
          top={13}
          textTransform="uppercase"
          fontSize="14px"
          color="text.disabled"
          fontWeight="bold"
        >
          <Box marginLeft="1.4rem">req</Box>
          <Box marginLeft="1rem">comp</Box>
          <Box></Box>
        </Box>
        <MenuList autoFocusItem={open}>
          {stepsConfig.map((config: PCPStepConfig) => (
            <MenuItem key={config.stepId} disableRipple sx={{ paddingRight: '1.2rem', height: '2.9rem' }}>
              <Checkbox
                disabled={!config?.requiredApiPath}
                checked={!config?.requiredApiPath || getValueByPath(userData || {}, config.requiredApiPath)}
                onChange={() => handleRequiredCheckboxClick(config)}
                sx={{ marginRight: '0.6rem' }}
              />
              <Checkbox
                checked={getValueByPath(userData || {}, config.completedApiPath)}
                onChange={() => handleCompletedCheckboxClick(config)}
                sx={{ marginRight: '1.2rem' }}
              />
              {config.title}
            </MenuItem>
          ))}
        </MenuList>
      </Popover>
    </>
  );
}
